import React, { useContext } from 'react';
import DataContext from '../context/questionnaires';
import QuizOptionButton from './button/quizOptionButton';

const Quiz = () => {
    const { showQuiz, question, questionnaire, checkAnswer, addAnswer, correctAnswers,
        selectedAnswers, questionIndex, nextQuestion, showTheResult, checked, resetQuiz } = useContext(DataContext);

    let options = [];
    if (question !== undefined && question.options !== undefined) {
        for (const [key, value] of Object.entries(question?.options)) {
            let classVar = 'bg-white';

            if (selectedAnswers.includes(key) && checked === false) {
                classVar = 'bg-cyan-400';
            }

            if (correctAnswers.includes(key) && selectedAnswers.includes(key) && checked === true) {
                classVar = 'bg-lime-500';
            }

            if (!correctAnswers.includes(key) && selectedAnswers.includes(key) && checked === true) {
                classVar = 'bg-red-700';
            }

            if (correctAnswers.includes(key) && !selectedAnswers.includes(key) && checked === true) {
                classVar = 'border border-lime-500';
            }

            if (!correctAnswers.includes(key) && !selectedAnswers.includes(key) && checked === true) {
                classVar = 'border border-red-600';
            }

            let button = <QuizOptionButton index={key} value={value} classes='inline-flex w-full items-left gap-2 rounded border p-2 text-sm font-medium text-black outline-none disabled:cursor-not-allowed disabled:opacity-60 h-max' classVar={classVar} onClickFn={addAnswer} />

            options.push(button);
        }
    }

    return (
        <div style={{ display: `${showQuiz ? 'block' : 'none'}` }}>
            <div id="quiz-popup" tabIndex="-1"
                className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex">
                <div className="relative p-4 w-full max-w-md h-full md:h-auto">

                    <div className="relative bg-white rounded-lg shadow">
                        <div
                            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center popup-close">{questionnaire.indexOf(question) + 1} / {questionnaire?.length}
                        </div>

                        <div className="p-5">

                            <div className="text-left">
                                <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                                    {question?.question}
                                </p>
                                {
                                    question?.images?.length > 0 ?
                                        question?.images.map((imagePath) => (
                                            <img src={imagePath} />
                                        ))
                                        :
                                        ''
                                }
                            </div>

                            <div className="mt-7 flex flex-col gap-2">

                                {options}
                            </div>

                            <div className="flex w-full items-center gap-2 py-6 text-sm text-slate-600">
                                <div className="h-px w-full bg-slate-200"></div>
                            </div>

                            {
                                (checked === false) ?
                                    <button type='button' onClick={checkAnswer} className={`inline-flex h-10 w-full items-center justify-center gap-2 rounded border p-2 text-sm font-medium text-white outline-none ${checked === true ? 'bg-sky-900' : 'bg-sky-500'}`} disabled={checked}>Check</button>
                                    :
                                    ''
                            }

                            {
                                ((questionIndex + 1) !== questionnaire.length) && checked === true ?
                                    <button className="inline-flex h-10 w-full items-center justify-center gap-2 rounded border p-2 text-sm font-medium text-white outline-none bg-cyan-500" onClick={nextQuestion} disabled={!selectedAnswers.length}>Next Question</button>
                                    :
                                    ''
                            }

{
                                ((questionIndex + 1) === questionnaire.length) && (checked === true) ?
                                    <button className="inline-flex h-10 w-full items-center justify-center gap-2 rounded border p-2 text-sm font-medium text-white outline-none bg-cyan-500" onClick={showTheResult} disabled={!selectedAnswers}>Show Result</button>
                                    :
                                    ''
                            }

                            <button className="inline-flex h-10 w-full items-center justify-center gap-2 rounded border p-2 text-sm font-medium text-white outline-none bg-yellow-600" onClick={resetQuiz}>Abbrechen</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Quiz;