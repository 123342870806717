import { createContext, useState, useEffect } from "react";

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const [questionnaires, setQuestionnaires] = useState({});
  const [questionnaire, setQuestionnaire] = useState([]);
  const [question, setQuestion] = useState({});
  const [questionIndex, setQuestionIndex] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [checked, setChecked] = useState(false);
  const [marks, setMarks] = useState(0);
  const [selectedQuiz, setSelectedQuiz] = useState('');

  const [showStart, setShowStart] = useState(true);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    fetch('questionnaires.json')
      .then(res => res.json())
      .then(data => setQuestionnaires(data))
  }, []);

  useEffect(() => {
    if (questionnaire.length > questionIndex) {
      setQuestion(questionnaire[questionIndex]);
    }
  }, [questionnaire, questionIndex])

  const startQuiz = (selectedSheet) => {
    console.log(selectedSheet)
    if (selectedSheet === undefined || selectedSheet === ''){
      selectedSheet = getSheets()[0]
    }

    setSelectedQuiz(selectedSheet)
    setQuestionnaire(questionnaires[selectedSheet]);
    setShowStart(false);
    setShowQuiz(true);
    setChecked(false);
  }

  const checkAnswer = (event, selected) => {
    let answers = selectedAnswers;
    let correct = question["correct_answers"].sort()
    answers.sort();
    setCorrectAnswers(correct);

    console.log(event);
    console.log(selected);

    if (answers.length === correct.length && JSON.stringify(answers) === JSON.stringify(correct)) {
      console.log('yes');
      setMarks(marks + 5);
    } else {
      console.log('oh noez');
    }
    setChecked(true);
  }

  const addAnswer = (event, selected) => {
    let test = selectedAnswers;
    if (!test.includes(selected)) {
      test.push(selected);

      setSelectedAnswers(test);
      event.target.classList.remove('bg-white');
      event.target.classList.add('bg-cyan-400');
    }
    else {
      const index = test.indexOf(selected);
      if (index > -1) {
        test.splice(index, 1);
        setSelectedAnswers(test);
        event.target.classList.remove('bg-cyan-400');
        event.target.classList.add('bg-white');
      }
    }

    console.log(selectedAnswers);
  }
  const getSheets = () => {
    console.log('sheets')
    return Object.keys(questionnaires)
  }

  const nextQuestion = () => {
    setCorrectAnswers([]);
    setSelectedAnswers([]);
    setChecked(false);
    const wrongBtn = document.querySelector('button.bg-red-700');
    wrongBtn?.classList.remove('bg-red-700');
    const rightBtn = document.querySelector('button.bg-lime-500');
    rightBtn?.classList.remove('bg-lime-500');
    setQuestionIndex(questionIndex + 1);
  }

  const showTheResult = () => {
    setShowResult(true);
    setShowStart(false);
    setShowQuiz(false);
  }

  const resetQuiz = () => {
    setShowStart(true);
    setShowResult(false);
    setShowQuiz(false);
    setCorrectAnswers([]);
    setSelectedAnswers([]);
    setQuestionIndex(0);
    setMarks(0);
    setChecked(false);
    const wrongBtn = document.querySelector('button.bg-red-700');
    wrongBtn?.classList.remove('bg-red-700');
    const rightBtn = document.querySelector('button.bg-lime-500');
    rightBtn?.classList.remove('bg-lime-500');
  }
  return (
    <DataContext.Provider value={{
      startQuiz, showStart, showQuiz, question, questionnaire, checkAnswer, addAnswer, correctAnswers,
      selectedAnswers, questionIndex, nextQuestion, showTheResult, showResult, marks,
      resetQuiz, checked, getSheets
    }} >
      {children}
    </DataContext.Provider>
  );
}

export default DataContext;

