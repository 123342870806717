import React, { useContext } from 'react';
import DataContext from '../context/questionnaires';

const Result = () => {
    const { showResult, questionnaire, marks, resetQuiz } = useContext(DataContext);
    return (

        <div style={{ display: `${showResult ? 'block' : 'none'}` }}>
            <div id="quiz-result" tabIndex="-1"
                className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full items-center justify-center flex">
                <div className="relative p-4 w-full max-w-md h-full md:h-auto">

                    <div className={`relative text-light text-center p-5 rounded ${marks > (questionnaire.length * 5 / 2) ? 'bg-lime-600' : 'bg-red-700'}`}>
                        <div className="p-5">
                            <div className="text-left">
                                <p className="mb-3 text-2xl font-semibold leading-5 text-slate-900">
                                    {marks > (questionnaire.length * 5 / 2) ? 'Awesome!' : 'Oops!'}
                                </p>
                            </div>

                            <div className="mt-7 flex flex-col gap-2">
                                Dein Ergebnis ist {marks} von {questionnaire.length * 5}
                            </div>

                            <div className="flex w-full items-center gap-2 py-6 text-sm text-slate-600">
                                <div className="h-px w-full bg-slate-200"></div>
                            </div>

                            <button onClick={resetQuiz} className='btn py-2 px-4 btn-light fw-bold d-inline'>Start Over</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Result;