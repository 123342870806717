import React from 'react'
import { cn } from '../../utils/cn'

const QuizOptionButton = ({index, value, classes = '', classVar = '', onClickFn}) => {
    return (
        <button
            key={index}
            className={cn(classes, classVar)}
            onClick={(event) => onClickFn(event, index)}
        >
            {index}: {value}
        </button>
    )
}

export default QuizOptionButton