import React from 'react';
import Start from './components/Start';
import Quiz from './components/Quiz';
import Result from './components/Result';
import { DataProvider } from './context/questionnaires';

function App() {
  return (
    <DataProvider>
      <main className='bg-blue-400 min-h-screen'>
        {/* Welcome Page */}
        <Start />

        {/* Quiz Page */}
        <Quiz />

        {/* Result Page */}
        <Result />
      </main>
    </DataProvider>
  );
}

export default App;
