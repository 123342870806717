import React, { useContext, useState } from 'react';
import DataContext from '../context/questionnaires';

const Start = () => {
    const { startQuiz, getSheets } = useContext(DataContext);

    const [selectedValue, setSelectedValue] = useState('');

    const options = getSheets() 
    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <div className="container pt-24 md:pt-36 mx-auto flex flex-wrap flex-col md:flex-row items-center">
            <div className="flex flex-col w-full xl:w-2/5 justify-center lg:items-start overflow-y-hidden">
            <h1 className="my-4 text-3xl md:text-5xl text-white opacity-75 font-bold leading-tight text-center md:text-left">
            Sachkundenachweis für den 
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-pink-500 to-purple-500">
                {' '}Hundeführerschein{' '}
                </span>
                Quiz
            </h1>
            <p className="leading-normal text-base md:text-2xl mb-8 text-center md:text-left">
                
            </p>
                <div className="mb-4">
                <label className="block text-blue-300 py-2 font-bold mb-2" for="emailaddress">
                    Bitte einen Fragebogen wählen:
                </label>
                <select className="bg-blue-500 p-2 w-150 rounded" value={selectedValue} onChange={handleSelectChange}>
                    {options?.map((option, index) => (
                         <option key={index} value={option}>{option}</option>
                     ))}
                 </select>
                </div>

                <div className="flex items-center justify-between pt-4">
                <button onClick={() => startQuiz(selectedValue)} className="bg-gradient-to-r from-purple-800 to-green-500 hover:from-pink-500 hover:to-green-500 text-white font-bold py-2 px-4 rounded focus:ring transform transition hover:scale-105 duration-300 ease-in-out">Start</button>
                </div>
            </div>
        </div>
    );
};

export default Start;